<template>
  <div>
    <Breadcrumbs area="Configurações" :data="location" />
    <div class="content">
      <Panel :data="location" />

      <div class="primary" :class="profile && profile.company_data ? '' : 'mt-none'" v-if="showImportations">
        <div class="box table" v-if="verifyTrial.has_plan">
          <div class="header">
            <p>
              Para importar o histórico ou dados antigos da sua loja, você deve fazer o upload de um arquivo CSV no campo
              abaixo.
              <a href="https://blog.vdash.com.br/como-importar-historico-de-pedidos-no-vdash-via-csv/" target="_blank">
                Saiba como fazer a importação aqui.
              </a>
            </p>
          </div>

          <div class="subheader">
            <div class="div-inputfile">
              <div class="title">Arquivo .CSV</div>
              <file-select v-model="file" @uploadFile="uploadFile"></file-select>
            </div>
          </div>

          <div class="painel">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>Nome do Arquivo</th>
                  <th>Data de Importação</th>
                  <th>Quantidade de Itens</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableData.items" :key="index">
                  <td class="table__name">
                    <p>{{ item.file_name }}</p>
                  </td>
                  <td>{{ item.created_at | filterDataHora }}</td>
                  <td>{{ item.size }}</td>
                  <td class="table__icone" v-bind:class="item.status">
                    <img
                      v-if="item.status == 'pending'"
                      src="~@/assets/images/icones/icon-pending.svg"
                      title="Importação pendente"
                      v-b-tooltip.hover.top
                    />
                    <img
                      v-if="item.status == 'success'"
                      src="~@/assets/images/icones/icon-table-check.svg"
                      title="Importado com sucesso"
                      v-b-tooltip.hover.top
                    />
                    <img
                      v-if="item.status == 'fail'"
                      src="~@/assets/images/icones/icon-table-close.svg"
                      title="Ocorreram erros na importação"
                      v-b-tooltip.hover.top
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="box table empty">
          <Logo class="logo" />
          <h1>Importação dos pedidos.</h1>
          <h2>Use o CSV da VTEX para importar o histórico dos seus pedidos.</h2>
          <p>Funcionalidade indisponível no período de teste grátis</p>
          <p>Assine um plano agora e tenha um relatório completo dos últimos meses.</p>
          <router-link class="btn btn-sucess" to="/settings/plan">Ver planos</router-link>
        </div>
      </div>

      <div class="primary" :class="profile && profile.company_data ? '' : 'mt-none'" v-else>
        <div class="table">
          <Alerta
            type="warning"
            titulo="Alerta"
            mensagem="Importação de histórico ou dados antigos via CSV disponível somente para integrações VTEX CMS (Legacy)."
            idMemoria="warningImportVtex"
            :hidable="false"
          />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Alerta from "@/components/alerta/Alerta.vue";
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import Panel from "@/components/panel/Panel.vue";
import FileSelect from "@/components/file-select/FileSelect.vue";
import Logo from "@/components/logos/LogoPositivo.vue";
import { getAllCSV, uploadCSV } from "@/api/importacao";
import { listIntegrations } from "@/api/integrations";
import { filterDataHora } from "@/utils/date";
export default {
  components: {
    Breadcrumbs,
    FileSelect,
    Panel,
    Logo,
    Alerta,
  },
  data() {
    return {
      location: "",
      tableData: {},
      file: null,
      showImportations: false
    };
  },
  mounted() {
    this.listIntegrations();
    this.fillData();
    this.getAllCSV();
  },
  computed: {
    ...mapState({
      profile: state => state.user.dadosCompletos,
      verifyTrial: state => state.plano.verifyTrial,
      integrations: state => state.integrations.integrations,
    }),
    getTable() {
      return this.$store.state.importacao.csv;
    },
  },
  filters: {
    filterDataHora,
  },
  methods: {
    listIntegrations,
    getAllCSV,
    uploadCSV,
    uploadFile() {
      this.uploadCSV(this.file);
      this.$emit("uploadFile", false);
      setTimeout(() => {
        this.file = null;
      }, 500);
    },
    fillData() {
      this.location = "Importações";
    },
    loadTable(value) {
      const items = [];

      if (value.data) {
        value.data.map(data => {
          items.push({
            file_name: data.file_name,
            created_at: data.created_at,
            size: data.size,
            status: data.status,
          });
        });
      }

      let data = {
        items,
      };
      return (this.tableData = data);
    },
  },
  watch: {
    getTable(data) {
      this.loadTable(data);
    },
    integrations(integrations) {
      if (integrations) {
        integrations.map((integration) => {
          if (integration.type == 'vtexlegacy') {
            this.showImportations = integration.active; // Só vai ativar se a integração VTEX estiver active = true
          }
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.empty {
  text-align: center;
  .btn {
    margin-top: 40px;
  }
  h1 {
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 20px;
  }
  h2 {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.logo {
  max-width: 120px;
}
.primary {
  width: 100%;
  margin-top: 65px;
}
.content {
  display: flex;
}
.box {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  margin-top: 0 !important;
  max-width: calc(50% - 7.5px);
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 18px;
  box-shadow: 0 3px 6px #c3d0e029;
  border-radius: 20px;
  &:nth-child(2) {
    margin-right: 0;
  }
  .table {
    border: 1px solid #bebebe;
    border-radius: 10px;
    border-collapse: separate;
    max-width: 977px;
    margin-top: 60px;
    tr {
      th {
        padding: 25px 50px;
        white-space: nowrap;
        border-right: 1px solid #bebebe;
        font-weight: 600;
        color: #2c2c2c;
        &:last-child {
          border-right: 0;
        }
      }
      td {
        max-width: 20ch;
        padding: 15px 50px;
        text-align: center;
        vertical-align: middle;
        border-right: 1px solid #bebebe;
        &:last-child {
          border-right: 0;
        }
        p {
          word-wrap: break-word;
          overflow-wrap: break-word;
          margin-bottom: 0;
        }
      }
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
  .table__icone {
    img {
      width: 30px;
    }
  }
  .table__name {
    p {
      max-width: 20ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      transition: all 350ms ease-in-out;
      cursor: pointer;
      &:hover {
        max-width: none;
        white-space: pre-line;
      }
    }
  }
  .painel {
    position: relative;
    min-height: 300px;
    display: block;
    overflow: auto;
  }
  .header {
    p {
      color: $gray;
      a {
        display: block;
        color: $gray;
        text-decoration: underline;
        margin-top: 25px;
        &:hover {
          color: #fd3064;
        }
      }
    }
  }
  .div-inputfile {
    .title {
      font-size: 16px;
      color: #2c2c2c;
      font-weight: 500;
      margin-bottom: 10px;
      display: inline-block;
      width: 100%;
    }
  }
  .subheader {
    margin-top: 68px;
  }
  &.table {
    margin: 0;
    margin-top: 15px;
    height: auto;
    max-width: 100%;
    width: 100%;
    &:after {
      content: "";
      display: block;
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        background-color: #41a7ff;
        max-width: 330px;
        width: 100%;
        height: 50px;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
}
</style>

import client from "@/api";
import store from "@/store";
// Caminhos
import { _rotaImportCSV } from "@/api/_caminhosApi";

export function getAllCSV() {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaImportCSV())
    .then(resp => {
      store.commit("importacao/SET_CSV", resp.data);
    })
    .catch(erro => {
      console.log("erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function uploadCSV(file) {
  store.commit("interacoes/SET_LOADING", true);

  let formData = new FormData();
  formData.append("import_file", file);

  client
    .post(_rotaImportCSV(), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(resp => {
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: resp.data.message,
        tipo: "sucesso",
      });
      getAllCSV();
    })
    .catch(erro => {
      console.log("erro", erro.response);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

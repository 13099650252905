<template>
  <div class="file-select">
    <div class="file-select__input">
      <div v-if="value">
        <span class="file-select__name">{{ value.name }}</span>
        <span class="file-select__close" @click="handleFileReset"></span>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div v-if="value" class="file-select__button --success" @click="handleFileUpload">
        <span>Enviar</span>
      </div>

      <label class="file-select__label" v-if="!value">
        <div class="file-select__button">
          <span>Escolher Arquivo</span>
        </div>
        <input id="fileChooser" type="file" @change="handleFileChange" />
      </label>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    value: File,
  },
  data() {
    return {
      file: this.value,
    };
  },
  methods: {
    handleFileChange(e) {
      this.$emit("input", e.target.files[0]);
    },
    handleFileReset() {
      document.getElementById("fileChooser").value = "";
      this.$emit("input", null);
    },
    handleFileUpload() {
      this.$emit("uploadFile", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.file-select {
  display: flex;
  max-width: 553px;
  position: relative;
  z-index: 9;
  &__input {
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    height: 50px;
    line-height: 1.5;
    color: $gray;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0;
    width: 100%;
    max-width: 361px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    position: relative;
  }
  &__close {
    width: 30px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    z-index: 99;
    cursor: pointer;
    &::before {
      content: "\00d7";
      color: $gray;
      font-weight: 300;
      font-family: Arial, sans-serif;
    }
    &:hover {
      &::before {
        color: #ff2e63;
      }
    }
  }
  &__button {
    padding: 1rem;
    color: white;
    border-radius: 0.3rem;
    text-align: center;
    font-weight: bold;
    height: 50px;
    width: 174px;
    background: $gray;
    color: $white;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    &.--success {
      background: #41a7ff;
    }
  }
  input[type="file"] {
    display: none;
  }
}
</style>
